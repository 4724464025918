@media (max-width: 768px) {
    .adaptive-1 {
        display: none !important;
    }
    .adaptive-2 {
       
    }
    #react-admin-title{
        display: none !important;
    }
    .RaList-actions {
        margin-top: 1rem;
    }
    [class*='MuiToolbar-root-RaTopToolbar-root'] {
        min-height: 0 !important;
    }
}

@media (min-width: 768px) {
    .adaptive-1 {
        //display: block;
    }
    .adaptive-2 {
        display: none !important;
    }
    .RaList-actions{
        max-width: 80vw;
    }
}

@media (max-width: 768px){
    .tasks-views {
        width: 90vw;
        max-width: 90vw;
    }
}

.css-1q2ae6k-MuiToolbar-root-RaTopToolbar-root {
    background-color: transparent !important;
}

.tasks-views .MuiFormHelperText-root {
    display: none !important;
}

.MuiSelect-icon, .MuiSelect-iconFilled, .MuiAutocomplete-popupIndicator {
    display: none !important;
}

.RaList-actions {
    display: flex;
    align-items: center !important;
}

.filterForm .MuiFormHelperText-root{
    display: none;
}

@keyframes blinkingBorder {
    0% { border-color: #FF5005; }
    50% { border-color: transparent; }
    100% { border-color: #FF5005; }
}